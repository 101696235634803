import React, { useEffect } from 'react'
import InputField from 'components/InputField';
import PrimaryButton from 'components/Button/PrimaryButton';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import axiosInstance from 'utils/redux/axios';
import { URLS } from 'utils/constants/urls';
import { useDispatch, useSelector } from 'react-redux';
import { cancellableOrders, setOrderHistoryApiData, returnableOrders, orderHistoryPage, orderHistoryRow, setTotalElements } from 'utils/redux/reducer/order-history-slice';
import { CancelModal } from '../CancelModal';
import OrderReturn from 'screens/OrderReturn';
import { cancelEnquiry, returnEnquiry } from './helper';
const OrderSearch = ({ refresh }: { refresh: any }) => {
      const [orderNumberInput, setOrderNumberInput] = React.useState<any>('');
      const [openCancelModal, setOpenCancelModal] = React.useState(false);
      const [openReturnDrawer, setOpenReturnDrawer] = React.useState(false);
      const [finalCancelData, setFinalCancelData] = React.useState([]);
      const [finalReturnData, setFinalReturnData] = React.useState([]);
      const cancellableData: any = useSelector(cancellableOrders);
      const returnableData: any = useSelector(returnableOrders);
      const page = useSelector(orderHistoryPage);
      const row = useSelector(orderHistoryRow);
      const dispatch = useDispatch();
      useEffect(() => {
            getOrderHistory();
      }, [page, row, refresh])
      const onSearchSubmit = async (e: any) => {
            e.preventDefault();
            getOrderHistory();
      }
      const handleCancelButtonClick = async () => {
            const items = await cancelEnquiry(cancellableData?.orderNumber, cancellableData?.products)
            if (items) {

                  cancellableData.products = items;
                  setFinalCancelData(cancellableData);
                  setOpenCancelModal(true);
            }


      }
      const handleReturnButtonClick = async () => {
            const items = await returnEnquiry(returnableData?.orderNumber, returnableData?.products)
            if (items) {
                  returnableData.products = items;
                  setFinalReturnData(returnableData);
                  setOpenReturnDrawer(true);
            }


      }


      const getOrderHistory = async () => {
            const body = { page: page + 1, size: row, filter: [], sort: ['createdAt:desc'], graphql: '' };
            body.graphql = "id,productCode,productName,variantCode,variantName,price,quantity,orderLineId,cancelledQuantity,customerOrder[id,firstName,lastName,email,phone,orderDate,orderId,orderStatus,amount,orderNumber,redeemableLoyaltyPoints,createdAt,createdBy,custRefNo,orderDateString,transactionId,storeId,posNo,promiseId,invoices[invoiceNumber],orderLineItems[orderLineId,variantCode,status]]"
            const { data } = await axiosInstance.post(URLS.myOrders(orderNumberInput), body);
            dispatch(setOrderHistoryApiData(data.content))
            dispatch(setTotalElements(data.totalElements))

      }
      return (
            <div>
                  <Box sx={{ maxWidth: "400px" }}><InputField size="small" onChange={(e) => setOrderNumberInput(e.target.value)} /> <PrimaryButton onClick={onSearchSubmit}>Search</PrimaryButton></Box>
                  <Box sx={{ maxWidth: "400px", display: "flex", gap: '20px', marginTop: "15px" }}>
                        <PrimaryButton onClick={() => handleCancelButtonClick()}>Cancel</PrimaryButton>
                        <PrimaryButton onClick={() => handleReturnButtonClick()}>Return</PrimaryButton>
                  </Box>
                  {openCancelModal && finalCancelData && <CancelModal open={openCancelModal} setOpen={(bool: any) => setOpenCancelModal(bool)} tableData={finalCancelData} key={(finalCancelData as unknown) as React.Key} />}
                  {openReturnDrawer && finalReturnData && <OrderReturn openDrawer={openReturnDrawer} onDrawerClose={() => setOpenReturnDrawer(false)} data={finalReturnData as any} key={finalReturnData as unknown as React.Key} />
                  }



            </div>
      )
}

export default OrderSearch;
